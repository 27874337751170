/* eslint-disable no-bitwise */
import { FormInstance } from 'antd/lib/form';
import * as moment from 'moment-timezone';
import { AppRoles } from '../../authorization/Permissions';
import { message } from 'antd';
import humanizeDuration from 'humanize-duration';
import dayjs from 'dayjs';
import { Dayjs } from 'dayjs';

export class Utils {
    static readonly uploadAcceptExtensions = '.doc,.docx,.xls,.xlsx,.pdf,.xltm,.xlsm';
    static htmlTags = /<(.|\n)*?>/g;
    private static symbols = /[()!.-1234567890:_=+@#$%^&*±[\]\n\t\\/]/gmi;
    private static spaces = /\s{2,}/gmi;    

    static clearText(text: string | null) {
        if (!text) {
            return text;
        }

        return text.replace(this.symbols, '').replace(this.spaces, ' ');
    }

    static downloadFile(url: string, mapForm: HTMLFormElement, formId: string) {
        mapForm.action = url;
        document.body.appendChild(mapForm);
        mapForm.submit();
        document.getElementById(formId)!.remove();
    }

    static readableFileSize(bytes: number, si: boolean) {
        var thresh = si ? 1000 : 1024;
        if (Math.abs(bytes) < thresh) {
            return bytes + ' B';
        }
        var units = si
            ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
            : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
        var u = -1;
        do {
            bytes /= thresh;
            ++u;
        } while (Math.abs(bytes) >= thresh && u < units.length - 1);
        return bytes.toFixed(1) + ' ' + units[u];
    }

    static formatDateStringLong (date: string | number | null) {
        if (date) {
            let dateVal: Date = new Date(date);
            if (dateVal instanceof Date && (!dateVal.getTime || isNaN(dateVal.getTime()))) {
                dateVal = new Date(Number.parseInt(date as string, 10));
            }
            return Intl.DateTimeFormat('en-Gb', {
                year: 'numeric',
                month: 'long',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit'
            }).format(dateVal);
        } else {
            return '';
        }
    }

    static convertDateStringToMoment (date: string | number ) {
        let dateVal: Date = new Date(date);
        if (dateVal instanceof Date && (!dateVal.getTime || isNaN(dateVal.getTime()))) {
            dateVal = new Date(Number.parseInt(date as string, 10));
        }
        return moment(dateVal);
    }

    static formatDateLong (date: Date | null) {
        if (date) {
            return Intl.DateTimeFormat( this.getRegionLocales(), {
                year: 'numeric',
                month: 'long',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit'
            
            }).format(date);
        } else {
            return '';
        }
    }

    static formatDateStringShort (date: string | null, excludeTime: boolean = false, excludeSeconds: boolean = false, 
                                  excludeDate: boolean = false, ignoreCurrentTimezone: boolean = false) {
        if (date) {
            const parsedDate = ignoreCurrentTimezone ? this.parseDateIgnoreTimeZone(date) : new Date(date);
            return Intl.DateTimeFormat( this.getRegionLocales(), {
                year: excludeDate ? undefined: 'numeric',
                month: excludeDate ? undefined: '2-digit',
                day: excludeDate ? undefined: '2-digit',
                hour: excludeTime ? undefined: '2-digit',
                minute: excludeTime ? undefined: '2-digit',
                second: excludeSeconds ? undefined : '2-digit',
            }).format(parsedDate);
        } else {
            return '';
        }
    }

    static safeFormatDateString (date: string | null, format: string = 'YYYY-MM-DD', inputFormat: string = 'DD-MM-YYYY') {
        try {
            if (!date) { 
                return '';
            }
            
            let dateString = moment(new Date(date), inputFormat).format(format);
            return dateString.includes('Invalid') ? date : dateString;
        } catch {
            return date;
        }
    }

    static dateStringToIso (date: string | null) {
        try {
            if (!date) { 
                return '';
            }
            
            let dateString = new Date(date).toISOString();
            return dateString.includes('Invalid') ? date : dateString;
        } catch {
            return date;
        }
    }

    static getDuration(start: string | number, end: string | number | undefined | null) {
        if (!end) {
            return undefined;
        }
        
        const duration = new Date(end).getTime() - new Date(start).getTime();
        return humanizeDuration(duration);
    }

    static prepareRuntimeDataObjectFromForm(form: FormInstance, packageId: string | null = null) {
        const values = form!.getFieldsValue();
        var obj = {};

        if (packageId) {
            obj = {PackageId: {value: packageId}};
        }

        for (var key of Object.getOwnPropertyNames(values)) {
            obj[key] = {value: form.getFieldValue(key)};
        }

        return obj;
    }

    static capitalizeWord(s: string) {
        return s.replace(/^\w/, (c) => c.toUpperCase());
    }

    static unCapitalizeWord(s: string) {
        return s.replace(/^\w/, (c) => c.toLowerCase());
    }

    static insertWhitespaceBeforeCapital(s: string) {
        s = s.replace(/[^0-9a-z]/gi, '');
        s = s.replace(/([a-z])([A-Z])/g, '$1 $2');
        s = s.replace(/([A-Z])([A-Z][a-z])/g, '$1 $2');
        return s;
    }


    static stringToHash(s: string) {
        let hash = 0;
        for (let i = 0; i < s.length; i ++) {
            // tslint:disable-next-line:no-bitwise
            hash = s.charCodeAt(i) + ((hash << 5) - hash);
        }
        return hash;
    }

    static intToRGB(i: number) {
        // tslint:disable-next-line:no-bitwise
        const c = (i & 0x00FFFFFF)
            .toString(16)
            .toUpperCase();
    
        return '00000'.substring(0, 6 - c.length) + c;
    } 

    static handleDropdownItemSelection (e: React.MouseEvent<HTMLElement, MouseEvent>, sel: Selection) {
        const getTextNode = (node: ChildNode): ChildNode => {
            return node.lastChild === null ? node : getTextNode(node.lastChild);
        };
        const el = e.target as HTMLElement;
        if (el.className !== 'ant-select-item-option-content' || e.button !== 2) {
            return;
        }
        el.focus();
        const range = document.createRange();
        const textNode = getTextNode(el);
        range.setStart(textNode!, 0);
        range.setEnd(textNode!, (textNode as Text).length);
        sel!.removeAllRanges();
        sel!.addRange(range);
    
    }

    static  safeStringLocaleCompare = (a: string | undefined , b: string | undefined ) => {
        if (!b && a) {
            return 1;
        } else if (b && !a) {
            return -1;
        } else if (!b && !a) {
            return 0;
        } else {
            return a!.localeCompare(b!);
        }
    };

    static priorityCompare = (a: string, b: string) => {
        if (a === 'HIGH' && (b === 'MEDIUM' || b === 'LOW')) {
            return 1;
        } else if (a === 'MEDIUM' && b === 'LOW') {
            return 1;
        } else if (a === 'MEDIUM' && b === 'HIGH') {
            return -1;
        } else if (a === b) {
            return 0;
        }
        return -1;
    };

    static isExcelByExtension(name: string) {
        return name.toLowerCase().endsWith('xlsx') || name.toLowerCase().endsWith('xls') 
        || name.toLowerCase().endsWith('xltm') || name.toLowerCase().endsWith('xlsm');
    }

    static generateDocumentIconClass(documentName: string) {
        let colorClass: string = 'default';

        if (!documentName) {
            return colorClass;
        }

        const docNameLower = documentName.toLowerCase();

        if (docNameLower.endsWith('.pdf')) {
            colorClass = 'doc-pdf';
        }

        if (Utils.isExcelByExtension(docNameLower)) {
            colorClass = 'doc-xls';
        }

        if (docNameLower.endsWith('.doc') || docNameLower.endsWith('.docx')) {
            colorClass = 'doc-word';
        }

        if (docNameLower.endsWith('.xml')) {
            colorClass = 'doc-xml';
        }


        return colorClass;
    }

    static generateDocumentIcon(documentName: string) {
        const colorClass = Utils.generateDocumentIconClass(documentName);
        return `alpha-icon sm ${colorClass}`;
    }

    static getQuilModules() {
        return {
            toolbar: [
                ['bold', 'italic', 'underline', 'strike'],
                [{'list': 'ordered'}, {'list': 'bullet'}],
            ]
        };
    }

    static getDateFormat(includeTime: boolean = false) {
        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const datePortion = timeZone.startsWith('America') ? 'MM/DD/YYYY ' : 'DD/MM/YYYY';
        return includeTime ? `${datePortion} HH:mm:ss` : datePortion;
    }

    static formatDatePickerValue(dateVal: string | null, excludeTime: boolean = false) {
        return dateVal ? dayjs(Utils.formatDateStringShort(dateVal, excludeTime), Utils.getDateFormat()) : undefined;
    }

    static escapeRegExp(str: string) {
        // eslint-disable-next-line no-useless-escape
        return str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&');
    }

    static getAppRoleDisplayName (roleName: string) {
        switch (roleName) {
        case AppRoles.admin:
            return 'System admin';
        case AppRoles.user:
            return 'User';
        default:
            return roleName;
        }
    }

    static isValidUTCDateTime (str: string) {
        const date = new Date(str);
        return !isNaN(date.getTime()) && str.endsWith('Z') && str.includes('T');
    }

    static filterOption (input: string, option: { label: string; value: string }) {
        return (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
    }

    static trimTitleString(title: string) {
        let newTitle = title;
        if (title.length > 150) {
            newTitle = title.substring(0, 149) + '...';
        }
        return newTitle;
    }

    static copyTaskUrlToClipboard(taskId: string) {
        const url = `${window.location.origin}/tasks/${taskId}`;
        let dummy = document.createElement('input');
        document.body.appendChild(dummy);
        dummy.value = url;
        dummy.select();
        dummy.setSelectionRange(0, 9999);
        document.execCommand('copy');
        document.body.removeChild(dummy);
        message.info('Copied to clipboard');
    }

    static getTaskPrioritiesForDropdown() {
        return [{value: 'LOW', label: 'Low'}, {value: 'MEDIUM', label: 'Medium'}, {value: 'HIGH', label: 'High'}];
    }

    static getDuePeriodDays(period: 'D' | 'W' | 'M' | 'Y') {
        switch (period) {
        case 'D': return 1;
        case 'W': return 7;
        case 'M': return 30;
        case 'Y': return 365;
        default: return 1;
        }
    }

    static removeLocalTimeZone(date: Dayjs) {
        const year = date.toDate().getFullYear();
        const month = date.toDate().getMonth() + 1;
        const day = date.toDate().getDate();
        const parsedMonth =  month < 9 ? `0${month}` : month;
        const parsedDay =  day < 10 ? `0${day}` : day;
        
        return `${year}-${parsedMonth}-${parsedDay}T00:00:00.000Z`;
    }

    static groupBy = <T, K extends string | number | symbol>(arr: T[], key: (i: T) => K) =>
        arr.reduce((groups, item) => {
            (groups[key(item)] ||= []).push(item);
            return groups;
        }, {} as Record<K, T[]>);

    private static getRegionLocales() {
        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        return timeZone.startsWith('Europe') ? 'en-Gb' : Intl.DateTimeFormat().resolvedOptions().locale;
    }

    private static parseDateIgnoreTimeZone(date: string) {
        const parsedDate = new Date(date);
        const userTimezoneOffset = parsedDate.getTimezoneOffset() * 60000;
        return new Date(parsedDate.getTime() + userTimezoneOffset);
    }
}

// eslint-disable-next-line max-len
export const EMAIL_REGEX_PATTERN = /[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?/g;

export const safeStringLocaleCompare = (a: string | undefined , b: string | undefined ) => {
    if (!b && a) {
        return 1;
    } else if (b && !a) {
        return -1;
    } else if (!b && !a) {
        return 0;
    } else {
        return b!.localeCompare(a!);
    }
};

export const FULL_DATE = 'FULL_DATE';
export const ONLY_DATE = 'ONLY_DATE';
export const ONLY_TIME = 'ONLY_TIME';
