import * as React from 'react';
import { TaskCommentsStore, TaskViewVisualStore } from '../stores';
import {
    Drawer,
    Row,
    Button,
    Collapse,
} from 'antd';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router';
import TaskPreview from './TaskPreview';
import TaskPreviewHeader from '../../common/components/TaskPreviewHeader';
import TaskCommentsSection from '../../common/components/TaskFullViewSections/TaskCommentsSection';
import { Editor, ProseMirror } from '@progress/kendo-react-editor';
import { SimpleToolbar } from '../../common/components/EditorToolbar';
import { placeholder, placeholderStyles } from 'src/modules/common/plugins/TelerikPlaceholder';

type Props = {
    store: TaskViewVisualStore;
    commentStore: TaskCommentsStore;
    subtasksRef?: React.MutableRefObject<null>
};

const TaskPreviewDialog: React.FC<Props> = ({ store, commentStore }) => {
    const { taskPreview } = store;
    const previewRef = React.useRef(null);
    const navigateTo = useNavigate();

    const handleMouseMove = React.useCallback((e: MouseEvent) => {
        if (!store.taskDrawerIsResizing) {
            return;
        }

        let offsetRight =
            document.body.offsetWidth - (e.clientX - document.body.offsetLeft);
        let minWidth = 404;
        let maxWidth = Math.max(window.innerWidth / 2, 600);
        if (offsetRight > minWidth && offsetRight < maxWidth) {
            store.setTaskDrawerWidth(offsetRight);
        }
    }, [store]);

    const handleMouseUp = React.useCallback(() => {
        store.setTaskDrawerIsResizing(false);
    }, [store]);

    React.useEffect(() => {
        document.addEventListener('mousemove', (e: MouseEvent) => handleMouseMove(e));
        document.addEventListener('mouseup', () => handleMouseUp());

        return (() => {
            document.removeEventListener('mousemove', (e: MouseEvent) => handleMouseMove(e));
            document.removeEventListener('mouseup', () => handleMouseUp());
        });
    }, [handleMouseMove, handleMouseUp]);

    React.useEffect(() => {
        if (taskPreview && taskPreview.projectId) {
            commentStore.loadComments(taskPreview.id);
        } else {
            commentStore.unloadComments();
        }
    }, [taskPreview, commentStore]);


    const handleMouseDown = () => {
        store.setTaskDrawerIsResizing(true);
    };

    if (!taskPreview) {
        return null;
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const getExpandIcon = (panelProps: any) => {
        return (
            <i style={!panelProps.isActive ? { transform: 'rotate(-90deg)', transition: 'transform 0.24s' } :
                { transition: 'transform 0.24s' }} className="alpha-icon xxxs arrow-down-icon" />
        );
    };

    const expandTask = () => {
        if (!taskPreview.isViewed) {
            store.updateField(taskPreview.id, 'IsViewed', true);
        }
        store.setPageIsLoading(true);
        navigateTo(`/tasks/${taskPreview.id}`);
    };

    const collapseActivityItems = [
        {
            key: 'activity',
            label: 'Activity',
            className: 'alpha-task-section-collapse-panel',
            children: <TaskCommentsSection store={store} commentsStore={commentStore} />,
        },
    ];

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onMount = (event: any) => {
        const state = event.viewProps.state;
        const plugins = [...state.plugins, placeholder('Add new comment ...')];
        const editorDocument = event.dom.ownerDocument;
        const styleElement = editorDocument && editorDocument.querySelector('style');
        if (styleElement) {
            styleElement.appendChild(editorDocument.createTextNode(placeholderStyles));
        }
        return new ProseMirror.EditorView({
            mount: event.dom
        }, {
            ...event.viewProps,
            state: ProseMirror.EditorState.create({
                doc: state.doc,
                plugins
            })
        });
    };

    return (
        (<Drawer
            data-id="dialog-container-right"
            title={(
                <Row>
                    <div className="drawer-resizer" onMouseDown={() => handleMouseDown()} />
                    <TaskPreviewHeader store={store} handleCollapseExpandTask={expandTask} isFullView={false} />
                </Row>
            )}
            maskClosable={false}
            placement="right"
            rootClassName="task-properties-modal drawer"
            open={store.showPreviewDialog}
            mask={false}
            closable={false}
            getContainer={false}
            width={store.taskDrawerWidth}
            footer={
                <div className="comment-input-container">
                    <div className="comment-input-wrapper" data-id={'input-comment'}>
                        <Editor
                            tools={SimpleToolbar} 
                            className={commentStore.taskNewComment  && commentStore.taskNewComment !== '<p></p>' ? '' : 'comment-input-empty'}
                            onChange={(e) => commentStore.setTaskNewComment(e.html)}
                            value={commentStore.taskNewComment}
                            contentStyle={{ height: 70, paddingLeft: 5, paddingRight: 5 }}
                            onMount={onMount}
                        />
                        {commentStore.taskNewComment  && commentStore.taskNewComment !== '<p></p>' && <div id="task-preview-comment" className="comment-controls">
                            <Button
                                type="primary"
                                disabled={!commentStore.canPostComment}
                                onClick={() => commentStore.postComment(taskPreview!.id)}
                                loading={commentStore.postingComment}
                            >
                                Comment
                            </Button>
                        </div>}
                    </div>
                </div>
            }
        >
            <div className="dialog-content-container" ref={previewRef}>
                <TaskPreview store={store} commentsStore={commentStore} isFullView={false} previewRef={previewRef} />
                <div data-id-type="collapse-container" data-id-name="Activity">
                    <Collapse expandIcon={getExpandIcon} bordered={false} className="alpha-task-section-collapse no-padding" items={collapseActivityItems}/>
                </div>
            </div>
        </Drawer>)
    );
};

export default observer(TaskPreviewDialog);