import { EditorTools } from '@progress/kendo-react-editor';

const {
    Bold,
    Italic,
    Underline,
    Strikethrough,
    AlignLeft,
    AlignCenter,
    AlignRight,
    AlignJustify,
    OrderedList,
    UnorderedList,
    FontSize,
    FormatBlock
} = EditorTools;

export const AdvancedToolbar = [
    [Bold, Italic, Underline, Strikethrough], 
    [AlignLeft, AlignCenter, AlignRight, AlignJustify],
    [OrderedList, UnorderedList],
    FontSize,
    FormatBlock];

export const SimpleToolbar = [
    [Bold, Italic, Underline, Strikethrough]
];