import React from 'react';
import TaskTemplateVisualStore from '../stores/TaskTemplatesVisualStore';
import { Button, Form, FormInstance, Input, Modal } from 'antd';
import { observer } from 'mobx-react-lite';
import { Editor } from '@progress/kendo-react-editor';
import { AdvancedToolbar } from '../../common/components/EditorToolbar';

type Props = {
    store: TaskTemplateVisualStore;
    parentForm: FormInstance;
    addCallback: (value: unknown) => void
};

const SubtaskTemplateDialog: React.FC<Props> = ({ store, parentForm, addCallback }) => {
    const [form] = Form.useForm();

    React.useEffect(() => {
        if (!store.selectedSubtaskTemplate) {
            form.resetFields();
        } else {
            form.setFieldsValue(store.selectedSubtaskTemplate.model);
        }
    }, [store.selectedSubtaskTemplate, form]);

    const handleSubmit = () => {
        form.validateFields().then(() => {
            const formData = form.getFieldsValue();

            if (store.selectedSubtaskTemplate?.index !== undefined) {
                const { subTasks } = parentForm.getFieldsValue();
                subTasks[store.selectedSubtaskTemplate?.index] = formData;
                parentForm.setFieldsValue({ subTasks });
            } else {
                addCallback(formData);
            }

            form.resetFields();
            store.setSelectedSubtaskTemplate(undefined);
        });
    };

    return (
        <Modal
            data-id-type="modal-window"
            data-id-name="edit-subtask-template"
            className="alpha-modal"
            title="Subtask template"
            open={store.selectedSubtaskTemplate !== undefined}
            closable={false}
            centered
            width={574}
            footer={[
                <Button
                    data-id="button-edit-subtask-template-cancel"
                    key="edit-subtask-template-cancel"
                    className="light"
                    onClick={() => {
                        store.setSelectedSubtaskTemplate(undefined);
                    }}
                >
                    Cancel
                </Button>,
                <Button
                    data-id="button-edit-subtask-template-save"
                    type="primary"
                    key="edit-subtask-template-save"
                    className="dark"
                    htmlType='submit'
                    form='edit-subtask-template-form'
                >
                    Save
                </Button>
            ]}
        >
            <Form
                form={form}
                className='alpha-form'
                id="edit-subtask-template-form"
                layout='vertical'
                onFinish={handleSubmit}
                initialValues={store.selectedSubtaskTemplate?.model}
            >
                <Form.Item
                    name="title"
                    colon={false}
                    className="dialog-field"
                    label={<span className="dialog-field-label">Title</span>}
                    rules={[{ required: true, message: 'Title is required' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="description"
                    colon={false}
                    className="dialog-field"
                    style={{ marginBottom: 5 }}
                    label={<span className="dialog-field-label">Description</span>}
                >
                    <Editor 
                        tools={AdvancedToolbar}
                        contentStyle={{ height: 150, fontSize: 14 }}
                        defaultEditMode='div'
                        onChange={(e) => form.setFieldValue('description', e.html)}
                    />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default observer(SubtaskTemplateDialog);