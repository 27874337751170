import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { PieChart, Pie, Cell } from 'recharts';
import { getPieChartLabel } from '../../common/components/PieChartLabel';

type Props = {
    statisicsData: {key: string; color: string; value: number; name: unknown}[]
};
const CustomPieChart: React.FC<Props> = ({statisicsData}) => {
    return (
        <PieChart width={200} height={200}  style={{outline: 'none'}}>
            <Pie 
                data={statisicsData} 
                labelLine={false}
                innerRadius={50} 
                dataKey="value" 
                nameKey="name" 
                cx="50%" 
                cy="50%" 
                outerRadius={100} 
                fill="#8884d8" 
                style={{outline: 'none'}}
                label={(props) => getPieChartLabel({...props, hasMultipleValues: statisicsData.length > 0})}
            >
                {statisicsData.map(
                    (d, index) => 
                        <Cell 
                            key={index} 
                            fill={statisicsData[index].color}
                            style={{outline: 'none'}}
                        />)}
            </Pie>
        </PieChart>
    );
};

export default observer(CustomPieChart);
