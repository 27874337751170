import { Button, Popconfirm, Tag, Tooltip } from 'antd';
import Table from 'antd/es/table';
import type { ColumnsType } from 'antd/es/table';
import { Observer, observer } from 'mobx-react-lite';
import React from 'react';
import { ProjectsStore } from 'src/modules/common/stores';
import { ALL_PROJECTS } from 'src/modules/tasks/screens/TasksPage';
import WidgetConstructorStore from '../stores/WidgetConstructorStore';
import { DashboardWidgetMeta, WidgetFilter, WidgetOutputField } from '../types/DashboardWidgetMeta';
import CreateWidgetDialog from './CreateWidgetDialog';

type Props = {
    store: WidgetConstructorStore;
    projectsStore: ProjectsStore;
    isCreateModalVisible: boolean;
    setIsCreateModalVisible: (val: boolean) => void
};
export const WidgetsTable: React.FC<Props> = ({ store, projectsStore, isCreateModalVisible, setIsCreateModalVisible }) => {

    const editWidget = (widget: DashboardWidgetMeta) => {
        store.setEditableWidget(widget.id);
        setIsCreateModalVisible(true);
    };

    const columns: ColumnsType<DashboardWidgetMeta> = [{
        key: 'name',
        dataIndex: 'name',
        title: 'Name',
    }, {
        key: 'widgetType',
        dataIndex: 'widgetType',
        title: 'Widget Type'
    },
    {
        key: 'projectId',
        dataIndex: 'projectId',
        title: 'Project',
        render: (projectId: string) => (
            <span>{projectId === ALL_PROJECTS ? 'All projects' : projectsStore.projects?.find(p => p.id === projectId)?.name}</span>
        )
    },
    {
        key: 'filters',
        dataIndex: 'filters',
        title: 'Filters',
        render: (filters: WidgetFilter[]) => (
            filters.length
        )
    },
    {
        key: 'outputField',
        dataIndex: 'outputField',
        title: 'Output FieldName',
        render: (fieldNames: WidgetOutputField[]) => {
            return (
                <Observer>{() => (
                    <>{fieldNames.map(f => {
                        const name = f.type === 'General' ? f.entityReference : store.metadata.find(m=> m.id === f.entityReference)?.name;
                        return (
                            <Tag key={f.entityReference}>{name}</Tag>
                        );
                    })}
                    </>)}
                </Observer>
            );
        }
    },
    {
        key: 'actions',
        title: null,
        render: (_: unknown, record: DashboardWidgetMeta) => (
            <div className="row-actions-wrapper" style={{textAlign: 'right'}}>
                <Tooltip title="Edit widget">
                    <Button
                        type="link"
                        onClick={() => editWidget(record)}
                        size="small"
                        data-id-cells="Edit widget"
                        data-id-name={record.name}
                    >
                        <i className="alpha-icon md table-action-edit" />
                    </Button>
                </Tooltip>
                <Popconfirm id="data-id-popconfirm-box" title="Are you sure you want to delete this widget?" onConfirm={() => {
                    store.deleteWidget(record.id);
                }}>
                    <Button data-id-cells="Delete widget" title="Delete" size="small" type="link">
                        <Tooltip title="Delete widget" placement="bottom">
                            <i className="alpha-icon md table-action-delete" />
                        </Tooltip>
                    </Button>
                </Popconfirm>
            </div>
        )
    }];

    return (
        <>
            <CreateWidgetDialog isVisible={isCreateModalVisible} closeDialog={() => setIsCreateModalVisible(false)} />
            <Table
                loading={store.isLoading}
                rowKey={(r) => r.id}
                className="alpha-table"
                columns={columns}
                dataSource={store.widgets}
                pagination={{
                    showSizeChanger: false,
                    pageSize: 20,
                    hideOnSinglePage: true
                }}
            />
        </>
    );
};

export default observer(WidgetsTable);

